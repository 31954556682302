<template>
  <div class="px-2 py-12 sm:px-8 mx-auto max-w-screen-xl">
    <h1 class="page-title">My account</h1>

    <div class="mt-6 max-w-screen-2xl w-full">
      <div class="grid grid-cols-2 gap-6 w-full">
        <div class="col-span-2 lg:col-span-1" >
          <div class="shadow-xl rounded-xl bg-white p-6">
            <div class="flex items-center justify-between pb-4 mb-4 border-b-2 border-gray-300">
              <h3 class="page-subtitle">Profile</h3>
              <div v-if="canChangeProfile">
                <div v-if="showProfileForm" class="btn btn--secondary" @click="showProfileForm = false">
                  <span>Cancel</span>
                </div>
                <div v-else class="btn btn--blue" @click="showProfileForm = true">
                  <i class="fa-solid fa-user-pen"></i>
                  <span>Edit profile</span>
                </div>
              </div>
            </div>

            <form v-if="showProfileForm" class="flex flex-col space-y-4" @submit.prevent="onChangeProfile">
              <div>
                <AppInputField v-model="profileForm.name" label="Name" placeholder="Name" type="text">
                  <i class="fa-regular fa-user"></i>
                </AppInputField>
              </div>

              <div>
                <AppInputField v-model="profileForm.email" label="Email" placeholder="Email" type="email">
                  <i class="fa-regular fa-envelope"></i>
                </AppInputField>
              </div>

              <div class="flex justify-center">
                <button type="submit" class="btn btn--primary">
                  <i class="fa-solid fa-lock"></i>
                  <span>Save</span>
                </button>
              </div>
            </form>

            <div v-else class="flex flex-col space-y-4">
              <dl class="dl">
                <dt class="dt">Name</dt>
                <dd class="dd">{{ sessionStore.user.name }}</dd>
              </dl>
              <dl class="dl">
                <dt class="dt">Email</dt>
                <dd class="dd">{{ sessionStore.user.email }}</dd>
              </dl>
              <dl class="dl">
                <dt class="dt">Login by</dt>
                <dd class="dd">{{ sessionStore.user.provider }}</dd>
              </dl>
              <dl class="dl">
                <dt class="dt">Member since</dt>
                <dd class="dd">{{ sessionStore.user.created_at }}</dd>
              </dl>
            </div>
          </div>

          <div v-if="canChangePassword" class="mt-8 shadow-xl rounded-xl bg-white p-6">
            <div class="flex items-center justify-between pb-4 mb-4 border-b-2 border-gray-300">
              <h3 class="page-subtitle">Password</h3>
              <div v-if="showPasswordForm" class="btn btn--secondary" @click="showPasswordForm = false">
                <span>Cancel</span>
              </div>
              <div v-else class="btn btn--blue" @click="showPasswordForm = true">
                <i class="fa-solid fa-lock"></i>
                <span>Change password</span>
              </div>
            </div>

            <form v-if="showPasswordForm" class="flex flex-col space-y-4" @submit.prevent="onChangePassword">
              <div>
                <AppInputField v-model="passwordForm.password" label="New password" placeholder="New password (min 6 characters)" type="password">
                  <i class="fa-solid fa-key"></i>
                </AppInputField>
              </div>

              <div>
                <AppInputField v-model="passwordForm.password_confirmation" label="New password confirmation" placeholder="New password confirmation" type="password">
                  <i class="fa-solid fa-key"></i>
                </AppInputField>
              </div>

              <div class="flex justify-center">
                <button type="submit" class="btn btn--primary">
                  <i class="fa-solid fa-lock"></i>
                  <span>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>

<!--        <section class="col-span-2 lg:col-span-1 shadow-xl rounded-xl bg-white p-4 w-full" >-->
<!--          <div class="flex items-center justify-between pb-4 mb-4 border-b-2 border-gray-300">-->
<!--            <h3 class="page-subtitle">Plan</h3>-->
<!--            <router-link :to="{ name: 'ProfileEdit' }" class="btn btn&#45;&#45;pink">-->
<!--              <i class="fa-solid fa-crown"></i>-->
<!--              <span>Change plan</span>-->
<!--            </router-link>-->
<!--          </div>-->

<!--          <div class="flex flex-col space-y-4">-->
<!--          </div>-->
<!--        </section>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {reactive, ref, computed} from "vue";
import { useAuth } from '$/composables/useAuth'
import { useNotify } from '$/composables/useNotify'
import AppInputField from "&/AppInputField.vue";

const { sessionStore } = useAuth()
const { pushNotify, pushError } = useNotify()
const showProfileForm = ref(false)
const showPasswordForm = ref(false)
const canChangeProfile = computed(() => sessionStore.user.provider === 'Email')
const canChangePassword = computed(() => sessionStore.user.provider === 'Email')

const profileForm = reactive({
  name: sessionStore.user.name,
  email: sessionStore.user.email,
})

const passwordForm = reactive({
  password: '',
  password_confirmation: ''
})

const onChangeProfile = function() {
  sessionStore
    .updateProfile(profileForm, {})
    .then(() => {
      let msg = 'Profile updated successfully! If you\'ve changed your email address, please check your inbox to confirm your new email.'
      pushNotify(msg)
      showProfileForm.value = false
    })
    .catch(err => {
      let error = err?.response?.data?.errors?.full_messages?.join('\n') || err?.message
      pushError(error)
    })
}
const onChangePassword = function() {
  sessionStore
    .changePassword(passwordForm, {})
    .then((res) => {
      pushNotify(res.data.message)
      showPasswordForm.value = false
    })
    .catch(err => {
      let error = err?.response?.data?.errors?.full_messages?.join('\n') || err?.message
      pushError(error)
    })
}
</script>

<style scoped lang="scss">
.dl {
  .dt {
    font-weight: bold;
    color: #2E2F30;
  }
  .dd {
    margin-left: 1rem;
  }
}
</style>

<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Shadow Generator: Add Depth and Drama to Your Images Instantly
      </h2>
      <p class="text-xl text-center mb-6">Create Dynamic Shadows in Real-Time with Our Advanced Shadow Generator</p>

      <div class="landing-page-feature-descriptions">
        <div class="landing-page-feature-description">
          <div class="col-span-12 lg:col-span-7">
            <img src="@/images/landing_pages/image_shadow_generator/1.png" alt="Image shadow generator"/>
          </div>
          <div class="lg:order-first col-span-12 lg:col-span-5">
            <h4>Create Stunning Shadows Instantly</h4>
            <p>Transform your photos with beautifully crafted shadow effects. Enhance depth and dimension in your images, making them stand out for any occasion—perfect for social media, product displays, and creative designs.</p>
          </div>
        </div>
      </div>

      <p class="mb-4">
        Revolutionize the way you enhance your images with the ability to add sophisticated shadows in real time.
        Perfect for e-commerce products, social media content, and graphic designs, our Shadow Generator lets you see
        the changes as you make them, allowing for precision and creativity without delays. Adjust shadow direction,
        blur, opacity, and more with intuitive sliders that offer immediate feedback and results.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Our Shadow Generator?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/realtime.png" alt="real-time shadow effects" />
          <strong>Real-Time Adjustments</strong>
          <p>
            Experience the impact of your edits immediately with our live shadow application, enhancing creativity and
            efficiency.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/customize.png" alt="customize shadows" />
          <strong>Full Customization</strong>
          <p>
            Tailor every aspect of your shadows to fit your vision perfectly. Experiment with endless combinations to
            get just the right effect.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/user-friendly.png" alt="user friendly" />
          <strong>Effortless User Interface</strong>
          <p>
            Our tool is designed for both novices and professionals alike, offering a balance of simplicity and advanced
            capabilities.
          </p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Simply drag and drop your image or upload it from your device.</li>
        <li>
          <strong>Apply Shadow:</strong> Use our sliders to adjust your shadow settings in real time and see the effects
          instantly.
        </li>
        <li>
          <strong>Preview & Fine-Tune:</strong> Preview the shadow effects and refine them until you achieve the desired
          look.
        </li>
        <li>
          <strong>Download:</strong> Download your professionally enhanced image, ready to make a visual impact
          anywhere.
        </li>
      </ol>

      <p class="">
        Optimized for seamless performance across all devices, our Shadow Generator equips you to create stunning
        visuals quickly and effortlessly, anytime, anywhere.
      </p>
    </div>
  </article>
</template>

import { createRouter, createWebHistory } from 'vue-router'

import AuthContainer from '&/auth/AuthContainer.vue'
import SignUp from '&/auth/SignUp.vue'
import SignIn from '&/auth/SignIn.vue'
import ForgotPassword from '&/auth/ForgotPassword.vue'
import ResetPassword from '&/auth/ResetPassword.vue'
import ResendConfirmationEmail from '&/auth/ResendConfirmationEmail.vue'
import AccountContainer from '&/auth/AccountContainer.vue'
import MyAccount from '&/auth/MyAccount.vue'

import TheContainer from "&/TheContainer.vue";
import FeatureIndex from '&/FeatureIndex.vue'
import FeatureRemoveBackground from '&/FeatureRemoveBackground.vue'
import FeatureRemoveBackgroundResult from '&/FeatureRemoveBackgroundResult.vue'
import FeatureUpscale from '&/FeatureUpscale.vue'
import FeatureUpscaleResult from '&/FeatureUpscaleResult.vue'
import FeatureBlurFace from '&/FeatureBlurFace.vue'
import FeatureBlurFaceResult from '&/FeatureBlurFaceResult.vue'
import FeatureBlurBackground from '&/FeatureBlurBackground.vue'
import FeatureBlurBackgroundResult from '&/FeatureBlurBackgroundResult.vue'
import FeatureGenerateShadow from '&/FeatureGenerateShadow.vue'
import FeatureGenerateShadowResult from '&/FeatureGenerateShadowResult.vue'
import FeatureCompressImage from '&/FeatureCompressImage.vue'
import FeatureCompressImageResult from '&/FeatureCompressImageResult.vue'
import FeatureCropImage from '&/FeatureCropImage.vue'
import FeatureCropImageResult from '&/FeatureCropImageResult.vue'
import FeatureResizeImage from '&/FeatureResizeImage.vue'
import FeatureResizeImageResult from '&/FeatureResizeImageResult.vue'
import FeatureConvertImage from '&/FeatureConvertImage.vue'
import FeatureConvertImageResult from '&/FeatureConvertImageResult.vue'
import FeatureRotateImage from '&/FeatureRotateImage.vue'
import FeatureRotateImageResult from '&/FeatureRotateImageResult.vue'

import FeaturePhotoToAnime from '&/FeaturePhotoToAnime.vue'
import FeaturePhotoToAnimeResult from '&/FeaturePhotoToAnimeResult.vue'
import FeatureColorizeBlackAndWhite from '&/FeatureColorizeBlackAndWhite.vue'
import FeatureColorizeBlackAndWhiteResult from '&/FeatureColorizeBlackAndWhiteResult.vue'

import PageFAQ from '&/landing_pages/PageFAQ.vue'

const routes = [
  {
    path: '/auth',
    component: AuthContainer,
    children: [
      { path: 'sign_up', name: 'SignUp', component: SignUp },
      { path: 'sign_in', name: 'SignIn', component: SignIn },
      { path: 'forgot_password', name: 'ForgotPassword', component: ForgotPassword },
      { path: 'reset_password', name: 'ResetPassword', component: ResetPassword },
      { path: 'resend_confirmation_email', name: 'ResendConfirmationEmail', component: ResendConfirmationEmail },
    ]
  },
  {
    path: '/profile',
    name: 'AccountContainer',
    component: AccountContainer,
    children: [{ path: '', name: 'MyAccount', component: MyAccount }]
  },

  {
    path: '/',
    component: TheContainer,
    children: [
      { path: '', name: 'FeatureIndex', component: FeatureIndex },
      { path: 'remove-background', name: 'RemoveBackground', component: FeatureRemoveBackground },
      { path: 'result/remove-background', name: 'RemoveBackgroundResult', component: FeatureRemoveBackgroundResult },
      { path: 'upscale-restore-image', name: 'Upscale', component: FeatureUpscale },
      { path: 'result/upscale-restore-image', name: 'UpscaleResult', component: FeatureUpscaleResult },
      { path: 'blur-face', name: 'BlurFace', component: FeatureBlurFace },
      { path: 'result/blur-face', name: 'BlurFaceResult', component: FeatureBlurFaceResult },
      { path: 'blur-background', name: 'BlurBackground', component: FeatureBlurBackground },
      { path: 'result/blur-background', name: 'BlurBackgroundResult', component: FeatureBlurBackgroundResult },
      { path: 'image-shadow-generator', name: 'GenerateShadow', component: FeatureGenerateShadow },
      { path: 'result/image-shadow-generator', name: 'GenerateShadowResult', component: FeatureGenerateShadowResult },
      { path: 'compress-image', name: 'CompressImage', component: FeatureCompressImage },
      { path: 'result/compress-image', name: 'CompressImageResult', component: FeatureCompressImageResult },
      { path: 'crop-image', name: 'CropImage', component: FeatureCropImage },
      { path: 'result/crop-image', name: 'CropImageResult', component: FeatureCropImageResult },
      { path: 'resize-image', name: 'ResizeImage', component: FeatureResizeImage },
      { path: 'result/resize-image', name: 'ResizeImageResult', component: FeatureResizeImageResult },
      { path: 'convert-image', name: 'ConvertImage', component: FeatureConvertImage },
      { path: 'result/convert-image', name: 'ConvertImageResult', component: FeatureConvertImageResult },
      { path: 'rotate-image', name: 'RotateImage', component: FeatureRotateImage },
      { path: 'result/rotate-image', name: 'RotateImageResult', component: FeatureRotateImageResult },
      { path: 'photo-to-anime-converter', name: 'PhotoToAnime', component: FeaturePhotoToAnime },
      { path: 'result/photo-to-anime-converter', name: 'PhotoToAnimeResult', component: FeaturePhotoToAnimeResult },
      { path: 'colorize-black-and-white-photo', name: 'ColorizeBlackAndWhite', component: FeatureColorizeBlackAndWhite },
      {
        path: 'result/colorize-black-and-white-photo',
        name: 'ColorizeBlackAndWhiteResult',
        component: FeatureColorizeBlackAndWhiteResult
      },

      { path: 'faq', name: 'PageFAQ', component: PageFAQ }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router

<template>
  <div class="grid grid-cols-2 gap-8 md:gap-0 h-full">
    <main class="col-span-2 flex items-center p-4">
      <div class="py-8 px-4 md:px-8 mx-auto flex flex-col justify-center bg-slate-50 rounded-lg shadow-lg w-full max-w-lg h-max">
        <div class="">
          <div class="flex flex-col space-y-8 items-center justify-center">
            <AppLogo/>
            <h1 class="gradient-title">Resend confirmation email</h1>
          </div>

          <div class="mt-12">
            <div class="mb-6 text-base text-center">
              Provide your email address to receive a new confirmation link. Please check your inbox and spam folder for the email.
            </div>

            <form class="default-form" @submit.prevent="onSubmit">
              <div>
                <AppInputField v-model="form.email" label="Email" placeholder="Email" type="email" autofocus required>
                  <i class="fa-solid fa-envelope"></i>
                </AppInputField>
              </div>

              <div class="flex justify-center">
                <button :disabled="store.sending" type="submit" class="btn btn--lg btn--primary">
                  <i class="fa-solid fa-paper-plane"></i>
                  <span>Send</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useAuth } from '$/composables/useAuth'

const { router, store, sessionStore, pushNotify, pushError, AppInputField, AppLogo } = useAuth()

const form = reactive({
  email: '',
})

const onSubmit = function() {
  store.sending = true
  sessionStore
    .reSendConfirmationEmail(form)
    .then((res) => {
      router.push({ name: 'FeatureIndex' })
      pushNotify(res.data.message)
    })
    .catch(err => {
      let error = err?.response?.data?.errors?.join('\n') || err?.message

      pushError(error)
    })
    .finally(() => store.sending = false)
}
</script>

import { defineStore } from "pinia";
import apiCall from "$/plugins/axios";

export const useFeatureStore = defineStore('feature', {
    state: () => ({
        features: [],
        feature: null,
        images: [],
        results: [],
        progressModal: null,
        submitImmediately: false,
        options: {
            'remove-background': {
                model: 'u2net'
            },

            'upscale-restore-image': {
                version: '1.3',
                upscale: 2
            },

            'detect-face': {
                detectionLevel: 'high'
            },

            'blur-face': {},

            'blur-background': {},

            'image-shadow-generator': {
                sigma: 20.0,
                opacity: 80,
                color: '#000000'
            },

            'crop-image': {},

            'compress-image': {
                convertToWebp: false,
                resizeWidth: null
            },

            'resize-image': {},

            'convert-image': {
                convertTo: 'PNG'
            },

            'rotate-image': {
                rotateAngle: 0
            },

            'photo-to-anime-converter': {
                model: 'style1'
            },

            'colorize-black-and-white-photo': {
                version: 2
            }
        },
    }),

    actions: {
        fetchFeatures() {
            return new Promise((resolve, reject) => {
                apiCall.get('/features')
                    .then(res => {
                        this.features = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchFeature(slug) {
            return new Promise((resolve, reject) => {
                apiCall.get(`/features/${slug}`)
                    .then(res => {
                        this.feature = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        execute(slug) {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                Object.values(this.images).forEach(v => formData.append('images[]', v))
                // Ensure each nested JSON object is stringified
                Object.keys(this.options[slug]).forEach(k => {
                    if (typeof this.options[slug][k] === 'object') { // Check if it's an object
                        formData.append(`options[${k}]`, JSON.stringify(this.options[slug][k]));
                    } else {
                        formData.append(`options[${k}]`, this.options[slug][k]);
                    }
                });
                apiCall.post(`/features/${slug}`, formData)
                    .then(res => {
                        this.results = res.data.results
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
})

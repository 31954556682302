<template>
  <div class="grid grid-cols-12 h-full mx-auto max-w-[1920px]">
    <main class="col-span-12 lg:col-span-9 h-full bg-zinc-100 pt-8 pb-32">
      <div class="flex flex-wrap lg:flex-nowrap justify-between p-4 lg:p-6">
        <div class="lg:max-w-[70%]">
          <h1 class="gradient-title mb-4">{{ featureStore.feature.name }}</h1>
          <div class="text-base">{{ featureStore.feature.description }}</div>
        </div>
        <AppImageUploader v-if="featureStore.images.length < maxUpload" :disabled="disabled" />
      </div>

      <slot>
        <div class="mx-auto max-w-screen-lg px-2">
          <div
            class="grid grid-cols-[repeat(auto-fit,_95%)] sm:grid-cols-[repeat(auto-fit,_45%)] lg:grid-cols-[repeat(auto-fit,_31%)] xl:grid-cols-[repeat(auto-fit,_23%)] justify-center gap-4"
            :style="imageWrapStyle"
          >
            <AppImagePreview v-for="image in featureStore.images" :key="image" class="" :image="image" />
          </div>
        </div>
      </slot>
    </main>

    <aside
      class="col-span-12 lg:col-span-3 bg-white lg:border-l-2 border-solid border-gray-200 h-full px-6 py-8 lg:py-16"
    >
      <div class="lg:sticky lg:top-12">
        <slot name="action">
          <button
            class="btn btn--lg btn--primary w-full"
            :disabled="disabled"
            @click="onSubmit(() => emit('finished'))"
          >
            <i class="fa-solid fa-bolt-lightning"></i>
            <span>{{ featureStore.feature.action }}</span>
          </button>
        </slot>

        <slot name="aside"> </slot>

        <div class="mt-12 flex flex-col space-y-8">
          <div v-if="overSizeLimit">
            <AppNotice notice-type="alert">
              {{ overSizeAlert }}
            </AppNotice>
            <router-link :to="{ name: 'CompressImage'}" class="btn btn--blue mt-8 block">Compress image here</router-link>
          </div>
          <div v-if="maxUpload && maxUpload > 1" class="">
            <AppNotice v-if="overUploadLimit" notice-type="alert">{{ overUploadAlert }}</AppNotice>
            <AppNotice v-else>{{ maxUploadHint }}</AppNotice>
          </div>
        </div>
      </div>
    </aside>
  </div>

  <ModalFeatureProgress />
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useStore } from '$/stores'
import { useFeatureStore } from '$/stores/feature'
import { useSubmit } from '$/composables/useSubmit'
import AppImageUploader from '&/AppImageUploader.vue'
import AppImagePreview from '&/AppImagePreview.vue'
import ModalFeatureProgress from '&/ModalFeatureProgress.vue'
import AppNotice from '&/AppNotice.vue'

const emit = defineEmits(['finished'])

const props = withDefaults(
  defineProps<{
    autorun?: boolean
    maxUpload?: number
    maxFileSize?: number
  }>(),
  {
    autorun: false,
    maxUpload: 1
  }
)

const store = useStore()
const featureStore = useFeatureStore()
const { onSubmit } = useSubmit()

const overUploadLimit = computed(() => featureStore.images.length > props.maxUpload)
const overSizeLimit = computed(() => featureStore.images.some(image => image.size > props.maxFileSize * 1024 * 1024))
const disabled = computed(() => {
  return overUploadLimit.value || overSizeLimit.value || store.sending;
})

const imageWrapStyle = ref('')
const maxUploadHint = ref(`You can edit up to ${props.maxUpload} images simultaneously to ensure optimal performance.`)
const overUploadAlert = computed(() => {
  return `You cannot upload more than ${props.maxUpload} images at once. Please reduce ${featureStore.images.length - props.maxUpload} images.`
})
const overSizeAlert = computed(() => {
  return `One or more images exceed the maximum file size of ${props.maxFileSize} MB. Please reduce the size of the files.`;
})

watch(
  () => featureStore.images,
  () => {
    if (props.autorun) {
      setTimeout(() => {
        onSubmit(() => emit('finished'))
      }, 1000)
    }
    if (window.screen.width >= 1024) {
      let itemWidth = null
      switch (featureStore.images.length) {
        case 1:
          itemWidth = 75
          break
        case 2:
          itemWidth = 48
          break
        case 3:
          itemWidth = 30
          break
        default:
          itemWidth = 23
      }
      imageWrapStyle.value = `grid-template-columns: repeat(auto-fit,${itemWidth}%) !important`
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => featureStore.submitImmediately,
  (val) => {
    if (val) {
      onSubmit(() => emit('finished'))
    }
  },
  {}
)
</script>

<template>
  <header class="">
    <nav
      class="shadow-lg bg-white dark:bg-gray-900 fixed w-full z-50 top-0 start-0 border-b border-gray-200 dark:border-gray-600"
    >
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-2">
        <div class="flex space-x-2 md:space-x-8 items-center">
          <a href="/" data-turbo="false" class="flex items-center space-x-1 sm:space-x-2 rtl:space-x-reverse">
            <img src="@/images/logo_128.png" class="h-8" alt="PicEditor" />
            <span>
              <span class="self-center text-lg md:text-3xl font-bold whitespace-nowrap text-primary">PicEditor</span>
              <span class="text-sm md:text-2xl font-semibold text-gray-600">.io</span>
            </span>
          </a>

          <router-link
            :to="{ name: 'FeatureIndex' }"
            class="hidden md:block py-2 text-lg font-medium text-dark hover:text-primary"
          >
            <i class="fa-solid fa-house"></i>
            <span>Home</span>
          </router-link>

          <div>
            <!-- Tool menu -->
            <button
              id="dropdownToolLink"
              data-dropdown-toggle="dropdownTool"
              data-dropdown-trigger="click"
              class="inline-flex items-center p-2 justify-center text-gray-500 rounded-lg sm:space-x-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <img src="@/images/icons/menu_tool.png" alt="All image tools" class="w-10 sm:w-12" />
              <span class="font-medium hidden md:block">All tools</span>
              <i class="hidden sm:block fa-solid fa-chevron-down"></i>
            </button>

            <div
              id="dropdownTool"
              class="hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-[96%] md:w-[700px] dark:bg-gray-700 dark:divide-gray-600"
            >
              <ul
                class="p-2 md:p-4 text-sm text-gray-700 dark:text-gray-400 flex flex-wrap"
                aria-labelledby="dropdownLargeButton"
              >
                <li
                  v-for="feature in featureStore.features"
                  :key="feature.slug"
                  class="text-sm md:text-base w-1/2 md:w-1/3"
                >
                  <router-link
                    :to="`/${feature.slug}`"
                    class="flex space-x-2 font-medium items-center rounded px-4 py-2 text-sm text-body-color hover:bg-teal-100 dark:text-dark-6"
                  >
                    <FeatureIcon :feature="feature" class="feature-icon w-1/5 sm:w-1/4" />
                    <span>{{ feature.name }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
            <!--------------------->
          </div>
        </div>

        <div class="flex md:order-1 md:space-x-0 rtl:space-x-reverse items-center flex-grow justify-end">
          <!-- User menu -->
          <button
            v-if="isLessThanSmWidth || sessionStore.isSignedIn"
            id="dropdownUserLink"
            data-dropdown-toggle="dropdownUser"
            data-dropdown-trigger="click"
            class="inline-flex items-center p-2 justify-center text-gray-500 rounded-lg sm:space-x-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <img
              v-if="sessionStore.isSignedIn"
              :src="sessionStore.user.image"
              alt="User profile"
              class="w-10 sm:w-12 rounded-full"
            />
            <img v-else src="@/images/icons/menu_user.png" alt="User profile" class="w-10 sm:w-12" />
            <span v-if="sessionStore.isSignedIn" class="hidden lg:block">{{ sessionStore.user.name }}</span>
            <i class="hidden sm:block fa-solid fa-chevron-down"></i>
          </button>
          <div
            id="dropdownUser"
            class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
          >
            <ul
              v-if="sessionStore.isSignedIn"
              class="py-2 text-sm text-gray-700 dark:text-gray-400"
              aria-labelledby="dropdownLargeButton"
            >
              <li>
                <div class="break-words px-4 py-2 font-medium text-gray-600">{{ sessionStore.user.name }}</div>
              </li>
              <li>
                <router-link
                  :to="{ name: 'MyAccount' }"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <i class="fa-solid fa-user w-5"></i>
                  <span>My account</span>
                </router-link>
              </li>
              <li class="mt-2 pt-2 border-t border-solid border-gray-200">
                <button
                  class="text-left block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  @click="onSignOut"
                >
                  <i class="fa-solid fa-right-from-bracket w-5"></i>
                  <span>Sign out</span>
                </button>
              </li>
            </ul>
            <ul v-else class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
              <li>
                <router-link
                  :to="{ name: 'SignIn' }"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <i class="fa-solid fa-right-to-bracket w-5"></i>
                  <span>Sign in</span>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'SignUp' }"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <i class="fa-solid fa-user-plus w-5"></i>
                  <span>Sign up</span>
                </router-link>
              </li>
            </ul>
          </div>
          <!--------------------->

          <div v-if="!isLessThanSmWidth && !sessionStore.isSignedIn" class="flex space-x-4 items-center">
            <router-link :to="{ name: 'SignIn' }" class="link">
              <i class="fa-solid fa-right-to-bracket"></i>
              <span>Sign in</span>
            </router-link>
            <router-link :to="{ name: 'SignUp' }" class="btn btn--primary">
              <i class="fa-solid fa-user-plus"></i>
              <span>Sign up</span>
            </router-link>
          </div>

          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            class="inline-flex items-center p-2 justify-center text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <img src="@/images/icons/menu_bar.png" alt="Menu" class="w-10 sm:w-12" />
          </button>
        </div>

        <div id="navbar-sticky" class="md:ml-4 items-center justify-between hidden w-full md:flex md:w-auto md:order-2">
          <ul
            class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
          >
            <li>
              <router-link
                :to="{ name: 'PageFAQ' }"
                class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                FAQ
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useFeatureStore } from '$/stores/feature'
import { useSessionStore } from '$/stores/session'
import { useFlowbite } from '$/composables/useFlowbite'
import FeatureIcon from '&/FeatureIcon.vue'

useFlowbite(['collapses', 'dropdowns'])

const featureStore = useFeatureStore()
const sessionStore = useSessionStore()

const onSignOut = function () {
  sessionStore.signOut().then(() => {
    window.location.href = '/'
  })
}

const isLessThanSmWidth = ref(window.innerWidth < 640)
window.addEventListener('resize', () => {
  isLessThanSmWidth.value = window.innerWidth < 640
})
</script>

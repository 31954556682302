<template>
  <ImgComparisonSlider class="comparison-slider transparent-wrap max-w-max" hover="hover" :value="sliderValue">
    <!-- eslint-disable -->
    <img slot="first" class="img" :src="first" alt="Before"/>
    <img slot="second" class="img" :src="second" alt="After" />
    <!-- eslint-enable -->

    <div slot="handle" class=" text-gray-200 text-lg flex space-x-4">
      <i class="fa-solid fa-angle-left"></i>
      <i class="fa-solid fa-angle-right"></i>
    </div>

    <template #handle>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
        <path
          stroke="#fff"
          d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2"
          stroke-width="1"
          fill="#4ade80"
          vector-effect="non-scaling-stroke"
        ></path>
      </svg>
    </template>
  </ImgComparisonSlider>
</template>

<script setup lang="ts">
import { ImgComparisonSlider } from '@img-comparison-slider/vue'
import { onMounted, ref } from 'vue'

const sliderValue = ref(100)

const props = withDefaults(defineProps<{
  first: string
  second: string
  stopAt: number
}>(), {
  stopAt: null
})

onMounted(() => {
  if (props.stopAt) {
    sliderValue.value = props.stopAt
  } else {
    setTimeout(() => {
      const interval = setInterval(() => {
        sliderValue.value--
        if (sliderValue.value <= 0) {
          clearInterval(interval)
        }
      }, 10)
    }, 1000)
  }
})
</script>

<style scoped lang="scss">
.comparison-slider {
  --divider-width: 2px;
  --divider-color: #4ade80;
  --default-handle-opacity: 0;
}

.img {
  width: 100%;
}
</style>

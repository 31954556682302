<template>
  <div class="text-gray-500 text-center text-lg mb-4">Or sign in with</div>

  <div class="flex flex-row space-x-4">
    <button
      class="btn w-full text-white bg-[#EA4335] hover:brightness-75"
      @click="oAuthSignIn('google_oauth2')"
    >
      <i class="fa-brands fa-google"></i>
      <span>Google</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { useAuth } from '$/composables/useAuth'

const { sessionStore, pushError } = useAuth()



$.auth.configure({
  apiUrl: '/api/v1',
  authProviderPaths: {
    google_oauth2: '/auth/google_oauth2',
  }
})

const oAuthSignIn = function(provider) {
  $.auth
    .oAuthSignIn({ provider })
    .then((res) => {
      sessionStore.saveToken(
        {
          access_token: res['access-token'],
          client: res.client,
          uid: res.uid
        }
      )
      setTimeout(() => {
        window.location.href = '/'
      }, 500)
    })
    .fail(function (res) {
      pushError('Authentication failure: ' + res.errors.join(' '))
    })
}
</script>

<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Auto Colorize: Bring Your Black & White Photos to Life with Vibrant Colors
      </h2>
      <p class="text-xl text-center mb-6">Effortlessly Add Color to Your Black and White Photos</p>
      <p class="mb-4">
        Breathe new life into your old black-and-white photos by transforming them into colorful, vibrant memories.
        Whether you're looking to restore family photos, enhance artistic projects, or simply explore the power of
        color, our Auto Colorize tool offers an easy and intuitive experience. Using advanced AI technology, your photos
        are seamlessly colorized, creating realistic and stunning results while preserving the essence of the original
        image.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Auto Colorize?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/color.png" alt="colorize photos" />
          <strong>Effortless Colorization</strong>
          <p>
            Add vibrant colors to any black-and-white photo with just a few clicks, perfect for restoring old memories
            or creating new art.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/ai.png" alt="AI-powered" />
          <strong>AI-Powered Precision</strong>
          <p>
            Our cutting-edge AI technology ensures accurate and lifelike colorization, making your photos look naturally
            colorful.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/creative.png" alt="enhance creativity" />
          <strong>Revitalize Old Photos</strong>
          <p>
            Bring old black-and-white photos back to life by adding color, preserving memories in a new and vibrant way.
          </p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li>
          <strong>Upload Your Photo:</strong> Drag and drop your black-and-white image or upload it from your device.
        </li>
        <li>
          <strong>Automatic Colorization:</strong> Our AI instantly processes your photo and adds realistic colors,
          bringing it to life.
        </li>
        <li>
          <strong>Preview & Adjust:</strong> Preview the colorized version and make any necessary adjustments to achieve
          the perfect look.
        </li>
        <li>
          <strong>Download:</strong> Download your newly colorized photo and share it with family, friends, or use it in
          your projects.
        </li>
      </ol>

      <p class="">
        Our Auto Colorize tool is optimized for both mobile and desktop devices, allowing you to easily add color to
        your black-and-white photos anytime, anywhere. No special skills are required—just your imagination and our
        user-friendly tool.
      </p>
    </div>
  </article>
</template>

import { defineStore } from "pinia";
import apiCall from "$/plugins/axios";

export const useStore = defineStore('', {
    state: () => ({
        sending: false,
        loading: true,
        ready: false,
        systemConfigs: {}
    }),

    actions: {
        setSending(status = true) {
            this.sending = status
        },
        setLoading(status = true) {
            this.loading = status
        },
        fetchSystemConfigs() {
            return new Promise(resolve => {
                apiCall.get('/system_configs')
                    .then(res => {
                        this.systemConfigs = res.data
                        resolve()
                    })
            })
        }
    }
})

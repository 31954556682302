<template>
  <footer class="p-6 bg-slate-100 z-50">
    <div class="flex justify-between mx-auto max-w-screen-xl">
      <div>
        <div class="">Created by <a href="https://fb.me/yuto.yasunaga.27" target="_blank" class="text-primary">Yuto Yasunaga</a></div>
      </div>


      <div>
        <ul>
          <li>
            <router-link :to="{ name: 'PageFAQ' }" class="link">FAQ</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex justify-center mt-4 pt-4 border-t border-gray-300">
      <div>&copy; PicEditor 2024</div>
    </div>
  </footer>
</template>
<script setup lang="ts">
</script>

<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" :multiple="false" />
      <PageUpscale />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished" :max-file-size="2">
        <template #aside>
          <div class="flex flex-col space-y-6 mt-8">
            <div class="flex flex-col space-y-2">
              <label for="version" class="input-label">Version</label>
              <div class="">
                <select
                  id="version"
                  v-model="featureStore.options['upscale-restore-image'].version"
                  class="input-field w-full"
                >
                  <option v-for="version in ['1.4', '1.3', '1.2']" :key="version">{{ version }}</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col space-y-2">
              <label for="upscale" class="input-label">Upscale</label>
              <div class="flex space-x-6 items-center">
                <div v-for="upscale in [2, 4]" :key="upscale" class="flex items-center">
                  <input
                    :id="`upscale_${upscale}`"
                    v-model="featureStore.options['upscale-restore-image'].upscale"
                    class="radio"
                    :value="upscale"
                    type="radio"
                  />
                  <label class="input-label" :for="`upscale_${upscale}`">{{ upscale }}x</label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '$/composables/useFeatureSetup'
import PageUpscale from '&/landing_pages/PageUpscale.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, featureStore, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'UpscaleResult' })
}
</script>

<template>
  <div class="feature-result">
    <FeatureResultSection :slug="featureStore.feature.slug" title="Your images have been successfully enhanced!" />

    <ResultSlider />
  </div>
</template>

<script setup lang="ts">
import { useFeatureStore } from '$/stores/feature'
import FeatureResultSection from '&/FeatureResultSection.vue'
import ResultSlider from '&/ResultSlider.vue'

const featureStore = useFeatureStore()
</script>

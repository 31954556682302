<template>
  <div class="overflow-x-scroll md:mt-4 p-2">
    <div v-for="(result, index) in featureStore.results" :key="result.image_url" class="flex flex-col justify-center">
      <div class="flex space-x-4 justify-center mb-4 mx-auto max-w-2xl">
        <div class="btn btn--sm btn--secondary" @click="showSlider = !showSlider">
          <i class="fa-solid fa-toggle-off"></i>
          <span>Change view</span>
        </div>

        <AppCopyResultImage :url="result.image_url" />
      </div>

      <div class="relative max-w-screen-xl mx-auto">
        <div v-if="result.old_width" class="absolute top-0 left-0 z-10">
          <span class="badge badge--xl shadow-lg">{{ result.old_width }} x {{ result.old_height }}</span>
        </div>
        <div v-if="result.new_width" class="absolute top-0 right-0 z-10">
          <span class="badge badge--xl shadow-lg badge--green">{{ result.new_width }} x {{ result.new_height }}</span>
        </div>

        <div v-if="showSlider" class="max-w-2xl mx-auto">
          <AppComparisonSlider :first="getImageURL(featureStore.images[index])" :second="result.image_url" />
        </div>

        <div v-else class="w-full flex max-w-screen-xl mx-auto relative">
          <img :src="getImageURL(featureStore.images[index])" class="w-1/2" />
          <img :src="result.image_url" class="w-1/2" />
        </div>
      </div>
    </div>

    <ModalFeatureProgress />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useFeatureStore } from '$/stores/feature'
import { useSubmit } from '$/composables/useSubmit'
import AppComparisonSlider from '&/AppComparisonSlider.vue'
import AppCopyResultImage from '&/AppCopyResultImage.vue'
import ModalFeatureProgress from '&/ModalFeatureProgress.vue'
import { getImageURL } from '$/utils'

const emit = defineEmits(['finished'])
const { onSubmit } = useSubmit()

const featureStore = useFeatureStore()
const showSlider = ref(true)

watch(
  () => featureStore.submitImmediately,
  (val) => {
    if (val) {
      onSubmit(() => emit('finished'))
    }
  },
  {}
)
</script>

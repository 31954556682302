import {onBeforeMount, onMounted, ref } from "vue";
import { getCurrentFeatureSlug } from "$/utils/index.js";
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "$/stores/index.js";
import { useFeatureStore } from "$/stores/feature.js";
import LayoutDefault from '&/LayoutDefault.vue'
import FeatureInitSection from '&/FeatureInitSection.vue'
import AppNotice from "&/AppNotice.vue";
import { closeDropdown } from "$/utils/index.js";

export function useFeatureSetup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const featureStore = useFeatureStore()
    const ready = ref(false)
    const slug = getCurrentFeatureSlug()

    onBeforeMount(() => {
        if (!route.query.keep_uploaded) {
            featureStore.images = []
            featureStore.results = []
        }
    })

    onMounted(() => {
        closeDropdown('dropdownTool', 'dropdownToolLink')

        featureStore.fetchFeature(getCurrentFeatureSlug())
            .finally(() => (ready.value = true))
    })

    return { route, router, store, featureStore, ready, slug, LayoutDefault, FeatureInitSection, AppNotice }
}

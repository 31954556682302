<template>
  <div class="overflow-x-scroll mt-6 p-2">
    <table class="table table--stripped shadow-lg mx-auto">
      <tbody>
        <tr v-for="(result, index) in featureStore.results" :key="result.image_url">
          <td v-if="showOrigin" class="min-w-32">
            <div class="flex space-x-1 justify-between items-center">
              <img :src="getImageURL(featureStore.images[index])" :alt="result.filename" class="mx-auto max-h-16" />
              <i class="fa-solid fa-arrow-right"></i>
            </div>
          </td>

          <td class="min-w-32">
            <img
              :src="result.image_url"
              :alt="result.filename"
              class="mx-auto"
              :class="`${bigResult ? 'max-h-96' : 'max-h-16'}`"
            />
          </td>

          <td v-if="showFilename" class="max-w-[320px] break-words">
            <div>{{ result.filename }}</div>
          </td>

          <td>
            <div class="flex flex-col space-y-2">
              <div v-if="showExtension" class="flex items-center justify-center">
                <span class="badge badge--lg">{{ result.old_extension }}</span>
                <span class="text-sm mx-1">
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
                <span class="badge badge--lg badge--green">{{ result.new_extension }}</span>
              </div>

              <div v-if="showDimension" class="flex items-center justify-center">
                <div class="badge badge--lg">
                  <span>{{ result.old_width }}</span>
                  <span class="mx-1">x</span>
                  <span>{{ result.old_height }}</span>
                </div>
                <span class="text-sm mx-1">
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
                <div class="badge badge--lg badge--green">
                  <span>{{ result.new_width }}</span>
                  <span class="mx-1">x</span>
                  <span>{{ result.new_height }}</span>
                </div>
              </div>

              <div v-if="showSize" class="flex items-center justify-center">
                <div>{{ formatBytes(result.old_filesize) }}</div>
                <span class="text-sm mx-1">
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
                <div class="font-semibold text-green-600">{{ formatBytes(result.new_filesize) }}</div>
              </div>

              <div v-if="showReduced" class="justify-center">
                <span class="mr-1">Reduced</span>
                <span class="font-bold text-lg text-primary">{{ result.reduced_rate }}%</span>
              </div>
            </div>
          </td>

          <td>
            <AppCopyResultImage :url="result.image_url" direction="col" />
            <div class="btn btn--sm btn--secondary w-full mt-2" @click="downloadImage(result.image_url)">
              <i class="fa-solid fa-file-arrow-down"></i>
              <span>Download</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { useFeatureStore } from '$/stores/feature'
import { formatBytes, downloadImage, getImageURL } from '$/utils'
import AppCopyResultImage from '&/AppCopyResultImage.vue'

withDefaults(
  defineProps<{
    bigResult?: boolean
    showFilename?: boolean
    showOrigin?: boolean
    showExtension?: boolean
    showSize?: boolean
    showDimension?: boolean
    showReduced?: boolean
  }>(),
  {
    bigResult: false,
    showFilename: false,
    showOrigin: false,
    showExtension: false,
    showSize: false,
    showDimension: false,
    showReduced: false
  }
)

const featureStore = useFeatureStore()
</script>

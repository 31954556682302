import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useStore } from '$/stores/index.js'
import { useSessionStore } from '$/stores/session.js'
import { useNotify } from '$/composables/useNotify'
import { closeDropdown } from "$/utils/index.js";
import ForgotPassword from "&/auth/ForgotPassword.vue";
import OAuth from '&/auth/OAuth.vue';
import AppLogo from "&/AppLogo.vue";
import AppInputField from "&/AppInputField.vue";

export function useAuth() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const sessionStore = useSessionStore()
    const { pushNotify, pushError } = useNotify()

    onMounted(() => {
        closeDropdown('dropdownUser', 'dropdownUserLink')

        if (sessionStore.isSignedIn &&  ['SignUp', 'SignIn'].includes(route.name)) {
            router.push({ name: 'FeatureIndex' });
        } else if (!sessionStore.isSignedIn &&  ['AccountContainer'].includes(route.name)) {
            router.push({ name: 'FeatureIndex' });
        }
    })

    return { route, router, store, sessionStore, pushNotify, pushError, ForgotPassword, OAuth, AppInputField, AppLogo }
}

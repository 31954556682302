<template>
  <div
    :data-tooltip-target="`tooltip-${image.name}`"
    class="shadow-lg rounded-lg flex flex-col space-y-1 w-full p-2 bg-white border border-solid border-white hover:border-sky-300"
  >
    <div
      :id="`tooltip-${image.name}`"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
    >
      {{ image.name }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>

    <div>
      <div class="cursor-pointer max-w-max ml-auto text-3xl hover:text-rose-600" @click="removeImage">
        <i class="fa-regular fa-circle-xmark"></i>
      </div>
    </div>

    <div class="flex-grow inline-flex items-center justify-center">
      <div class="transparent-wrap transition" :style="imageStyle">
        <img :src="imageURL" :alt="image.name" class="" @load="handImageLoad" />
      </div>
    </div>

    <div class="text-gray-600 text-center break-all text-sm line-clamp-1">{{ image.name }}</div>

    <section>
      <div v-if="featureStore.feature.slug === 'resize-image'" class="flex flex-wrap justify-center items-center">
        <div class="badge badge--dark">
          <span>{{ imageDimensions.width }}</span>
          <span class="mx-1">x</span>
          <span>{{ imageDimensions.height }}</span>
        </div>
        <span class="text-sm mx-1">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
        <div v-if="featureStore.options.resizeWidth && featureStore.options.resizeHeight" class="badge badge--green">
          <span>{{ featureStore.options.resizeWidth }}</span>
          <span class="mx-1">x</span>
          <span>{{ featureStore.options.resizeHeight }}</span>
        </div>
      </div>

      <div v-else-if="featureStore.feature.slug === 'convert-image'" class="flex flex-wrap justify-center items-center">
        <span class="badge badge--dark">{{ getImageExtension(image) }}</span>
        <span class="text-sm mx-1">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
        <span class="badge badge--green">{{ featureStore.options.convertTo }}</span>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useFeatureStore } from '$/stores/feature'
import { getImageExtension } from '$/utils'
import { useFlowbite } from '$/composables/useFlowbite'

useFlowbite(['tooltips'])

const featureStore = useFeatureStore()
const imageURL = ref('')
const imageDimensions = ref({})
const imageStyle = ref('')

const props = defineProps<{
  image: object
}>()

watch(
  () => featureStore.options['rotate-image'].rotateAngle,
  (angle) => {
    if (featureStore.feature.slug === 'rotate-image') {
      imageStyle.value = `
      transform: rotate(${angle}deg);
      -webkit-transform: rotate(${angle}deg);
      -moz-transform: rotate(${angle}deg);
      -o-transform: rotate(${angle}deg);
      -ms-transform: rotate(${angle}deg);
    `
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (props.image) {
    imageURL.value = URL.createObjectURL(props.image)
  }
})

function handImageLoad(event) {
  imageDimensions.value.width = event.target.naturalWidth
  imageDimensions.value.height = event.target.naturalHeight
}

function removeImage() {
  featureStore.images = featureStore.images.filter((e) => e !== props.image)
}
</script>

<template>
  <div class="grid grid-cols-2 gap-2">
    <AppImageOption v-model="featureStore.options[slug].model" name="modelOption" value="style1" label="Style 1">
      <img src="@/images/features/photo-to-anime-converter/style1.jpg" alt="Style 1" class="rounded-lg" />
    </AppImageOption>
    <AppImageOption v-model="featureStore.options[slug].model" name="modelOption" value="style2" label="Style 2">
      <img src="@/images/features/photo-to-anime-converter/style2.jpg" alt="Style 2" class="rounded-lg" />
    </AppImageOption>
    <AppImageOption v-model="featureStore.options[slug].model" name="modelOption" value="style3" label="Style 3">
      <img src="@/images/features/photo-to-anime-converter/style3.jpg" alt="Style 3" class="rounded-lg" />
    </AppImageOption>
    <AppImageOption v-model="featureStore.options[slug].model" name="modelOption" value="style4" label="Style 4">
      <img src="@/images/features/photo-to-anime-converter/style4.jpg" alt="Style 4" class="rounded-lg" />
    </AppImageOption>
    <AppImageOption v-model="featureStore.options[slug].model" name="modelOption" value="style5" label="Style 5">
      <img src="@/images/features/photo-to-anime-converter/style5.jpg" alt="Style 5" class="rounded-lg" />
    </AppImageOption>
    <AppImageOption v-model="featureStore.options[slug].model" name="modelOption" value="style6" label="Style 6">
      <img src="@/images/features/photo-to-anime-converter/style6.jpg" alt="Style 6" class="rounded-lg" />
    </AppImageOption>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useFeatureStore } from '$/stores/feature'
import AppImageOption from '&/AppImageOption.vue'

const featureStore = useFeatureStore()
const slug = 'photo-to-anime-converter'

watch(
  () => featureStore.options[slug].model,
  () => {
    featureStore.submitImmediately = true
  },
  {}
)
</script>

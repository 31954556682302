<template>
  <div class="feature-result">
    <FeatureResultSection :slug="featureStore.feature.slug" title="Your images have been successfully rotated!" />

    <ResultDefault show-origin show-filename show-dimension show-size />
  </div>
</template>

<script setup lang="ts">
import { useFeatureStore } from '$/stores/feature'
import FeatureResultSection from '&/FeatureResultSection.vue'
import ResultDefault from '&/ResultDefault.vue'

const featureStore = useFeatureStore()
</script>

<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Blur Face: Instantly Anonymize Faces with Precision
      </h2>
      <p class="text-xl text-center mb-6">Securely Blur Faces in Your Photos with Advanced Customizable Features</p>
      <p class="mb-4">
        Elevate your privacy control with our real-time Blur Face tool. Perfect for users needing to anonymize faces in
        photos for social media, journalism, or public sharing, our tool allows you to dynamically adjust, resize, and
        position blur boxes directly on your images. Customize the intensity and detection levels to match your specific
        needs, all with immediate visual feedback.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Our Blur Face Tool?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/realtime.png" alt="instant results" />
          <strong>Instant Results</strong>
          <p>
            Apply blur effects in real-time and see changes instantly, ensuring you achieve the desired anonymity with
            no delay.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/customize.png" alt="customizable blur" />
          <strong>Fully Customizable</strong>
          <p>
            Drag and resize blur boxes with ease. Adjust detection sensitivity to ensure precise face blurring tailored
            to your project.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/secure.png" alt="secure and private" />
          <strong>Secure and Private</strong>
          <p>
            Our tool respects your privacy with secure processing. Faces are blurred without storing personal data,
            ideal for sensitive content.
          </p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Simply drag and drop your image or upload it from your device.</li>
        <li>
          <strong>Adjust Blur Box:</strong> Click and drag to position the blur box over the face. Resize it to cover
          the desired area.
        </li>
        <li>
          <strong>Set Detection Level:</strong> Use the slider to adjust the detection sensitivity for perfect face
          recognition and blurring.
        </li>
        <li>
          <strong>Download:</strong> Download your image with faces blurred as needed, ready for any use while ensuring
          privacy.
        </li>
      </ol>

      <p class="">
        Optimized for seamless performance across all devices, our Blur Face tool lets you maintain privacy in your
        images effortlessly, anywhere, anytime.
      </p>
    </div>
  </article>
</template>

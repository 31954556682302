<template>
  <div class="px-4 mx-auto max-w-4xl">
    <div class="flex flex-col space-y-4 items-center">
      <h1 class="gradient-title">{{ title }}</h1>
      <h2 id="resultTop" class="text-lg lg:text-2xl text-center">
        Preview your images below and download with just a click
      </h2>
      <div
        class="w-full max-w-3xl mt-4 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center"
      >
        <button class="text-xl text-primary" @click="back">
          <i class="fa-regular fa-circle-left"></i>
          <span>Back</span>
        </button>

        <button class="btn btn--primary btn--lg" :disabled="store.sending" @click="download">
          <i class="fa-solid fa-download"></i>
          <span v-if="featureStore.results.length > 1">Download all {{ featureStore.results.length }} images</span>
          <span v-else>Download result image</span>
        </button>

        <div></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useStore } from '$/stores'
import { useFeatureStore } from '$/stores/feature'
import { downloadImage, downloadImages } from '$/utils'

const props = defineProps<{
  title: string
  slug: string
}>()

const router = useRouter()
const store = useStore()
const featureStore = useFeatureStore()

function back() {
  router.push({ path: `/${props.slug}`, query: { keep_uploaded: true } })
}

function download() {
  store.setSending(true)
  if (featureStore.results.length > 1) {
    const imageUrls = featureStore.results.map((result) => result.image_url)
    downloadImages(imageUrls).finally(() => store.setSending(false))
  } else {
    downloadImage(featureStore.results[0].image_url).finally(() => store.setSending(false))
  }
}
</script>

<template>
  <div class="feature-result">
    <FeatureResultSection :slug="featureStore.feature.slug" title="Your photo has been turned into anime style!" />

    <div class="grid grid-cols-12 gap-4 mx-auto max-w-screen-xl">
      <div class="col-span-12 md:col-span-9">
        <ResultSlider @finished="onFinished" />
      </div>
      <div class="col-span-12 md:col-span-3 px-4">
        <FeaturePhotoToAnimeOptions />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useFeatureStore } from '$/stores/feature'
import FeatureResultSection from '&/FeatureResultSection.vue'
import ResultSlider from '&/ResultSlider.vue'
import FeaturePhotoToAnimeOptions from '&/FeaturePhotoToAnimeOptions.vue'

const router = useRouter()
const featureStore = useFeatureStore()

function onFinished() {
  router.push({ name: 'PhotoToAnimeResult' })
}
</script>

<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Crop Image: Tailor Your Photos Perfectly
      </h2>
      <p class="text-xl text-center mb-6">Precisely Crop Your Photos to Highlight What Matters Most</p>
      <p class="mb-4">
        Whether you're creating content for digital marketing, social media, or just enhancing personal photos, our Crop
        Image tool allows you to perfectly frame your subject. Customize the composition of your photos with our
        user-friendly interface, enabling you to cut out unnecessary elements and focus on what’s important.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Crop Image?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/focus.png" alt="focus" />
          <strong>Enhance Focus</strong>
          <p>Direct viewer's attention to the subject of your image by removing distractions from the background.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/adjustment.png" alt="custom adjustments" />
          <strong>Custom Adjustments</strong>
          <p>
            Use our adjustable crop box to freely select the area you want to keep, or choose from common aspect ratios
            for perfect alignment.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/resolution.png" alt="high resolution" />
          <strong>Maintain Quality</strong>
          <p>
            Our tool ensures your cropped images are saved in high resolution, maintaining pristine image quality and
            clarity.
          </p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your image or upload it from your device.</li>
        <li>
          <strong>Select Crop Area:</strong> Adjust the crop box manually or select a fixed aspect ratio from the
          options provided.
        </li>
        <li>
          <strong>Preview & Adjust:</strong> Preview your cropped image and make any final adjustments to ensure the
          perfect crop.
        </li>
        <li>
          <strong>Download:</strong> Download your perfectly cropped image, ready for any use, whether personal or
          professional.
        </li>
      </ol>

      <p class="">
        Optimized for both desktops and mobile devices, our Crop Image tool lets you refine your photos anywhere,
        anytime, without the need for complicated software.
      </p>
    </div>
  </article>
</template>
